import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import ATextField from './InputField';
import ADateField from './DateField';
import ASelectField from './SelectField';
import {A_COMMON_DATA_FIELD} from '../../constants/aCommonDataField';
import {SELECT_CATEGORIES} from '../../constants/selectCategories';
import AMultiSelectField from './MultiSelectField';
import CheckboxInput from '../../common/inputs/common/CheckboxInput';
import './CommonDataField.scss';
import {useTranslation} from "react-i18next";
import ADateTimeField from "./DateTimeField";
import SwitchCheckboxInput from "../../common/inputs/SwitchCheckboxInput";

// TODO rename component to "type"

/**
 *
 * @param id
 * @param addComponent
 * @param helperText
 * @param disabled
 * @param currency
 * @param fulWidth
 * @param component can be "date", "text", "select-gender"
 * @param label
 * @param value
 * @param error
 * @param handleChange
 * @param handleBlur
 * @param placeholder
 * @param options
 * @param multiline
 * @param rows
 * @param className
 * @param fieldClassName
 * @param style
 * @param onLoadSecureField
 * @param isLoadedSecureField
 * @param hasClear
 * @param getOptionsDisabled
 * @param inputProps
 * @param autoSelect
 * @param datePickerComponentType
 * @param min value for input with type of number
 * @param max value for input with type of number
 * @param isBirthDate
 * @returns {JSX.Element}
 * @constructor
 */

const ACommonDataField = ({
                              id,
                              type,
                              addComponent,
                              helperText,
                              isJson,
                              isArray,
                              disabled,
                              currency,
                              fulWidth = false,
                              component = A_COMMON_DATA_FIELD.TEXT,
                              label,
                              secureFieldLabel,
                              value,
                              error,
                              handleChange,
                              handleBlur = () => {
                              },
                              placeholder,
                              options,
                              multiline,
                              rows,
                              className = '',
                              fieldClassName = '',
                              style,
                              filterSelection,
                              onLoadSecureField,
                              isLoadedSecureField,
                              hasClear,
                              getOptionsDisabled,
                              inputProps,
                              datePickerComponentType,
                              autoSelect,
                              min,
                              max,
                              isBirthDate,
                              allowFutureDate,
                          }) => {
    const {t} = useTranslation();
    const [hide, setHide] = useState(false);

    if (component.includes('select-') && value === '') {
        value = [];
    }

    useEffect(() => {
        if (onLoadSecureField) {
            setHide(true);
        }
    }, [])

    const onReveal = () => {
        onLoadSecureField();
        setHide(false);
    }

    if ((hide || !isLoadedSecureField) && onLoadSecureField) {
        return (
            <div className={'revealField'} onClick={onReveal}>
                {`${secureFieldLabel ? secureFieldLabel : label} - ${t("crm.button.reveal")}`}
            </div>
        )
    }

    //secureField
    return (
        <div className='content-row'

             style={{display: 'block', ...(component.includes('select-') ? {width: '100%'} : {})}}
        >
            {(component === A_COMMON_DATA_FIELD.TEXT ||
                component === A_COMMON_DATA_FIELD.PASSWORD ||
                component === A_COMMON_DATA_FIELD.NUMBER) && (
                <>
                    <ATextField
                        id={id}
                        fieldClassName={fieldClassName}
                        type={component === A_COMMON_DATA_FIELD.PASSWORD ? type : component}
                        placeholder={placeholder}
                        label={label}
                        secureFieldLabel={secureFieldLabel}
                        value={value}
                        fulWidth={fulWidth}
                        isJson={isJson}
                        isArray={isArray}
                        error={error}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        currency={currency}
                        disabled={disabled}
                        helperText={helperText}
                        multiline={multiline}
                        rows={rows}
                        style={style}
                        inputProps={inputProps}
                        min={min}
                        max={max}
                    />
                    {addComponent}
                </>
            )}

            {component === A_COMMON_DATA_FIELD.CHECK_BOX && (
                <div className={`check-box-aml ${className}`}>
                    <CheckboxInput
                        id={id}
                        checked={value}
                        onChange={handleChange}
                        disabled={disabled}
                    >
                        {label}
                    </CheckboxInput>
                </div>
            )}
            {component === A_COMMON_DATA_FIELD.TOGGLE && (
                <div className={` ${className}`}>
                    <SwitchCheckboxInput
                        id={id}
                        checked={value}
                        onChange={handleChange}
                        disabled={disabled}
                    >
                        {label}
                    </SwitchCheckboxInput>
                </div>
            )}
            {component === A_COMMON_DATA_FIELD.DATE && (
                <ADateField
                    fieldClassName={fieldClassName}
                    id={id}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    disabled={disabled}
                    helperText={helperText}
                    style={style}
                    datePickerComponentType={datePickerComponentType}
                    isBirthDate={isBirthDate}
                    allowFutureDate={allowFutureDate}
                />
            )}
            {component === A_COMMON_DATA_FIELD.DATE_TIME && (
                <ADateTimeField
                    fieldClassName={fieldClassName}
                    id={id}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    disabled={disabled}
                    helperText={helperText}
                    style={style}
                    datePickerComponentType={datePickerComponentType}
                    isBirthDate={isBirthDate}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_GENDER && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={'gender'}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_PROFESSION && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.PROFESSION}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_COUNTRIES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.COUNTRIES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                    className={className}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_STATES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={SELECT_CATEGORIES.STATES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                    autoSelect={autoSelect}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_CITIES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={SELECT_CATEGORIES.CITIES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                    autoSelect={autoSelect}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_CURRENCIES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={'currencies'}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_REQUIRE_FIELDS && (
                <AMultiSelectField
                    id={id}
                    category={SELECT_CATEGORIES.SELECT_REQUIRE_FIELDS}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_PAYMENT_SUPPLIERS && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={SELECT_CATEGORIES.SELECT_PAYMENT_SUPPLIERS}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_PAYMENT_METHODS && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.SELECT_PAYMENT_METHODS}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_BANK && (
                <ASelectField
                    filterSelection={filterSelection}
                    id={id}
                    category={SELECT_CATEGORIES.BANK}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_MULTI_CURRENCIES && (
                <AMultiSelectField
                    id={id}
                    className={className}
                    category={'currencies'}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_MULTI_VIP_LEVEL && (
              <AMultiSelectField
                id={id}
                className={className}
                category={SELECT_CATEGORIES.MULTI_VIP_LEVEL}
                label={label}
                value={value}
                error={error}
                handleChange={handleChange}
                fulWidth={fulWidth}
                getOptionsDisabled={getOptionsDisabled}
                disabled={disabled}
                helperText={helperText}
              />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_DEFAULT && options && (
                <ASelectField
                    filterSelection={filterSelection}
                    id={id}
                    category={SELECT_CATEGORIES.DEFAULT}
                    label={label}
                    className={className}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    style={style}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_IDENTIFICATION_TYPE && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={SELECT_CATEGORIES.IDENTIFICATION_TYPE}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_PAYMENT_AGENT_TYPE && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.PAYMENT_AGENT_TYPE}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_VIP_LEVEL && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.VIP_LEVEL}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_AGENT_OWNER && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.AGENT_OWNER}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.MULTI_SELECT_AGENT_OWNER && (
              <AMultiSelectField
                category={SELECT_CATEGORIES.AGENT_OWNER}
                label={label}
                value={value}
                error={error}
                handleChange={handleChange}
                className={className}
                fulWidth={fulWidth}
                getOptionsDisabled={getOptionsDisabled}
                disabled={disabled}
                helperText={helperText}
                options={options}
                hasClear={hasClear}
              />
            )}

            {component === A_COMMON_DATA_FIELD.MULTI_SELECT_PROMOTIONS_TEMPLATE && (
              <AMultiSelectField
                category={SELECT_CATEGORIES.PROMOTIONS_TEMPLATE}
                label={label}
                value={value}
                error={error}
                handleChange={handleChange}
                className={className}
                fulWidth={fulWidth}
                getOptionsDisabled={getOptionsDisabled}
                disabled={disabled}
                helperText={helperText}
                options={options}
                hasClear={hasClear}
              />
            )}

            {component === A_COMMON_DATA_FIELD.MULTI_SELECT_CASHIER_PAYMENT_METHODS && (
                <AMultiSelectField
                  category={SELECT_CATEGORIES.CASHIER_PAYMENT_METHODS}
                  label={label}
                  value={value}
                  error={error}
                  handleChange={handleChange}
                  className={className}
                  fulWidth={fulWidth}
                  getOptionsDisabled={getOptionsDisabled}
                  disabled={disabled}
                  helperText={helperText}
                  options={options}
                  hasClear={hasClear}
                />
              )}

            {component === A_COMMON_DATA_FIELD.KYC_REQUIRED && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.KYC_REQUIRED}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.BOOLEAN && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.BOOLEAN}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_CASHIER_BOOLEAN && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.SELECT_CASHIER_BOOLEAN}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_EXPERIENCE_LEVEL && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}

                    id={id}
                    category={SELECT_CATEGORIES.EXPERIENCE_LEVEL}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_DOCUMENT_TYPES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.DOCUMENT_TYPES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_DOCUMENT_SIDES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.DOCUMENT_SIDES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_DOCUMENT_STATUSES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.DOCUMENT_STATUSES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}
            {component === A_COMMON_DATA_FIELD.SELECT_PLAYER_ACCOUNT_TYPE && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    id={id}
                    category={SELECT_CATEGORIES.PLAYER_ACCOUNT_TYPE}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_LANGUAGES && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    category={SELECT_CATEGORIES.LANGUAGES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_MULTI_COUNTRIES && (
                <AMultiSelectField
                    category={SELECT_CATEGORIES.COUNTRIES}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    className={className}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_CATEGORISATION && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    category={SELECT_CATEGORIES.CATEGORISATION}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    options={options}
                    hasClear={hasClear}
                />
            )}

            {component === A_COMMON_DATA_FIELD.SELECT_PRODUCT && (
                <ASelectField
                    filterSelection={filterSelection}
                    style={style}
                    category={SELECT_CATEGORIES.PRODUCTS}
                    label={label}
                    value={value}
                    error={error}
                    handleChange={handleChange}
                    fulWidth={fulWidth}
                    getOptionsDisabled={getOptionsDisabled}
                    disabled={disabled}
                    helperText={helperText}
                    hasClear={false}
                />
            )}
        </div>
    );
};

ACommonDataField.propTypes = {
    component: PropTypes.string,
    type: PropTypes.string,
    label: PropTypes.string,
    currency: PropTypes.string,
    helperText: PropTypes.string,
    disabled: PropTypes.bool,
    fulWidth: PropTypes.bool,
    value: PropTypes.any,
    secureField: PropTypes.any,
    error: PropTypes.string,
    handleChange: PropTypes.func,
    handleBlur: PropTypes.func,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    multiline: PropTypes.bool,
    rows: PropTypes.number,
    className: PropTypes.string,
    fieldClassName: PropTypes.string,
    style: PropTypes.object,
    hasClear: PropTypes.bool,
    inputProps: PropTypes.object,
    datePickerComponentType: PropTypes.string,
    onLoadSecureField: PropTypes.func,
    isLoadedSecureField: PropTypes.bool,
    min: PropTypes.number,
    max: PropTypes.number,
    isBirthDate: PropTypes.bool,
};

export default React.memo(ACommonDataField);
