import {memo} from "react";
import {useTranslation} from "react-i18next";
import './styles.scss';
import {postSync} from "../../helpers/api_helper";
import { BI_SP_DASHBOARD_ID, BI_STATISTICS_DASHBOARD } from "../../constants/siteBrands"
import {SITE_PERMISSION} from "../../common/constants/common";
import PermissionWrapper from "../PermissionWrapper";
import { useSelector } from "react-redux"

const UserStatistics = (props) => {
    const { playerID } = props;
    const { t } = useTranslation();
    const { active: activeBrand } = useSelector(state => state.Brand);

    const handleClick = async () => {
        const url = "/dashboard/dashboard-url"
        try {
            const responseData = await postSync(url, {
                id: BI_STATISTICS_DASHBOARD[activeBrand?.siteName] || '',
                params: {
                    player_id: playerID,
                    site_id: activeBrand?.id
                }
            });
            if (responseData && responseData.url) {
                const resultUrl = responseData.url;
                window.open(resultUrl, '_blank');
            }
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <PermissionWrapper
            accessPermissions={[SITE_PERMISSION.Player__Metabase__Statistics__View]}
        >
            <div className='statistics-wrapper'>
                <button
                    className='view-full-statistics btn btn-rounded btn-primary'
                    onClick={handleClick}
                >
                    {t('crm.viewFullStatistics')}
                </button>
            </div>
        </PermissionWrapper>
    )
}

export default memo(UserStatistics);